import { ref, onMounted, reactive, defineComponent } from 'vue'
import DataTable from '@/views/components/table/HjpDataTable.vue'
import AgentForm from '@/views/pages/master-setting/partner-agent/Form.vue'
import {
  get, map
} from 'lodash'

export default defineComponent({
  components: {
    DataTable,
    AgentForm
  },
  setup() {
    const slotLocation = 'Address'

    const tabelParams = reactive({
      endpoint: '/management/v1/MsAgent',
      headerTitle: 'Master Mitra Agen',
      buttonTitle: 'Tambah Mitra Agen',
      editTitle: 'Edit',
      confirmDialogDelete: {
        title: 'Hapus Mitra Agen?',
        subTitle: 'Pastikan Mitra Agen yang Anda tambahkan sudah sesuai dan tidak ada perubahan ditengah-tengah.',
        btnSubmitTitle: 'Hapus Mitra Agen',
      },
      columnsTable: [
        {
          field: 'Name',
          headerName: 'Nama Agen',
          searchable: true,
          fieldSearch: 'name',
        },
        {
          field: 'Code',
          headerName: 'ID Agen',
          searchable: true,
          fieldSearch: 'code',
        },
        {
          field: 'Address',
          headerName: 'Lokasi',
          isRender: false
        },
      ]
    })
    // const slotBank = 'MsBank.Name'
    // const slotChannelBank = 'MsChannelBanks.OaAccount'
    // const getBankChannelId = (data: any) => {
    //   console.log('data', data)
    // }
    return {
      tabelParams,
      slotLocation
    }
  }
})
