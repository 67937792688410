/* eslint-disable */
import { Response } from '@/utils/helpers/response'
import {
  Remote as RemoteHjex,
  remoteEnum
} from '@/data/remote/remoteHjex'
import Repository from '@/data/repository'

class ApiUseCase {
  get(path: string, params: string = ''): Promise<Response> {
    return new Repository(new RemoteHjex(remoteEnum.get, `${path}${params}`), null).getResult(false)
  }

  postOrPut(path: any, id: any, data: any = null, param: any = ''): Promise<Response> {
    if (id) {
      return new Repository(new RemoteHjex(remoteEnum.put, `${path}/${id}${param}`, data), null).getResult(false)
    } return new Repository(new RemoteHjex(remoteEnum.post, `${path}`, data), null).getResult(false)
  }

  patch(path: any, id: any, data: any): Promise<Response> {
    return new Repository(new RemoteHjex(remoteEnum.patch, `${path}/${id}`, data), null).getResult(false)
  }

  delete(path: any, id: any, param: any = ''): Promise<Response> {
    return new Repository(new RemoteHjex(remoteEnum.delete, `${path}/${id}${param}`), null).getResult(false)
  }

  getUser(path: any, data: any): Promise<Response> {
    return new Repository(new RemoteHjex(remoteEnum.post, `${path}`, data), null).getResult(false)
  }

  put(path: string, data: any): Promise<Response> {
    return new Repository(new RemoteHjex(remoteEnum.put, `${path}`, data), null).getResult(false)
  }

  putWithNoId(path: any, data: any): Promise<Response> {
    return new Repository(new RemoteHjex(remoteEnum.put, `${path}`, data), null).getResult(false)
  }

  postWithNoId(path: any, data: any = null): Promise<Response> {
    return new Repository(new RemoteHjex(remoteEnum.post, `${path}`, data), null).getResult(false)
  }
}

const apiUseCase = new ApiUseCase()

export default apiUseCase
